import apiUrls from "src/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAppLoaderAction } from "src/common/state/loaderHandleMiddleware";
import { UserFormResults } from "../components/chatState";
import { IGptConversation } from "src/model/model";
import { RootState } from "src/common/state/store";
import axios from "axios";

interface IWixarAIStore {
  activeGptConversation?: IGptConversation | null;
  gptConversations: IGptConversation[];
}

const initialState: IWixarAIStore = {
  activeGptConversation: null,
  gptConversations: [],
};

export const fetchGptConversations = createAsyncThunk(
  "gpt/fetchGptConversations",
  // eslint-disable-next-line no-empty-pattern
  async ({}: Record<string, string> & IAppLoaderAction) => {
    const response = (await axios.get)<Array<IGptConversation>>(
      `${apiUrls.gpt.gptConversations}?sort=updatedAt:desc`,
    );
    return { data: (await response).data };
  },
);

export const createChat = createAsyncThunk(
  "gpt/createChat",
  async (
    {
      userPrompt,
      userFormResults,
      cb,
      cbOnRequestEnd,
    }: {
      userPrompt: string;
      userFormResults: UserFormResults;
      cb?: (chunk: string) => void;
      cbOnRequestEnd?: () => void;
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.createChat, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            userPrompt,
            userFormResults,
          },
        }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          if (cb) {
            cb(chunk);
          }
        }
      }

      const timestamp = new Date().toISOString();

      const newConversation: IGptConversation = {
        conversation_metadata: {
          model: "gpt-4",
          timestamp,
          userPrompts: [userPrompt],
          userFormResults: userFormResults,
        },
        conversation_data: {
          output: [botMessage],
        },
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      if (cbOnRequestEnd) {
        cbOnRequestEnd();
      }

      return newConversation;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (createChat)");
    }
  },
);

export const updateChat = createAsyncThunk(
  "gpt/updateChat",
  async (
    {
      userPrompt,
      conversationId,
      cb,
    }: {
      userPrompt: string;
      conversationId: number;
      cb?: (chunk: string) => void;
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.updateChat, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ userPrompt, conversationId }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          if (cb) {
            cb(chunk);
          }
        }
      }

      return { userPrompt, botResponse: botMessage };
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (updateChat)");
    }
  },
);

export const resetChat = createAsyncThunk(
  "gpt/resetChat",
  async ({ cb }: { cb(): any } & IAppLoaderAction) => {
    return await axios
      .put(apiUrls.gpt.resetChat)
      .then((res) => {
        cb();
        return res;
      })
      .catch((e) => {
        console.error("resetChat did an oopsie: ", e);
        return e;
      });
  },
);

export const askWixarpedia = createAsyncThunk(
  "gpt/askWixarpedia",
  async (
    {
      userPrompt,
      cb,
    }: {
      userPrompt: string;
      cb?: (chunk: string) => void;
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.askWixarpedia, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            userPrompt,
          },
        }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          if (cb) {
            cb(chunk);
          }
        }
      }

      return botMessage;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (askWixarpedia)");
    }
  },
);

export const updateGptConversationName = createAsyncThunk(
  "gpt/updateName",
  async (
    {
      id,
      newName,
      cb,
    }: {
      id: number;
      newName: string;
      cb?: () => void;
    },
    { rejectWithValue },
  ) => {
    try {
      return await axios
        .put(`${apiUrls.gpt.gptConversations}/${id}`, {
          name: newName,
        })
        .then((res) => {
          if (cb) {
            cb();
          }
          return res.data;
        })
        .catch((e) => {
          console.error("Error in chatbotSlice.ts :", e);
          throw e;
        });
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (updateGptConversationName)");
    }
  },
);

export const deleteGptConversation = createAsyncThunk(
  "gpt/deleteName",
  async (
    {
      id,
      cb,
    }: {
      id: number;
      cb?: () => void;
    },
    { rejectWithValue },
  ) => {
    try {
      return await axios
        .delete(`${apiUrls.gpt.gptConversations}/${id}`)
        .then((res) => {
          if (cb) {
            cb();
          }
          return res.data;
        })
        .catch((e) => {
          console.error("Error in chatbotSlice.ts 299:", e);
          throw e;
        });
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (deleteGptConversation)");
    }
  },
);

export const generateProjectGroupFromAiGraph = createAsyncThunk(
  "gpt/generateProjectGroupFromAiGraph",
  async (
    {
      conversationId,
      name,
      cb,
    }: {
      conversationId: number;
      name: string;
      cb?: () => void;
    } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios
        .post<{
          createdProjectGroup: { id: number };
        }>(`${apiUrls.gpt.generateTemplate}`, {
          name,
          conversationId,
          createAsProjectGroup: true,
        })
        .then((res) => {
          if (cb) {
            cb();
          }
          console.log("res in generateProjectGroupFromAiGraph thunk:", res);
          return res;
        });

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed (generateProjectGroupFromAiGraph)");
    }
  },
);

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    setActiveGptConversation: (state: IWixarAIStore, action) => {
      state.activeGptConversation = action.payload;
    },
    resetActiveGptConversation: (state: IWixarAIStore) => {
      state.activeGptConversation = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGptConversations.fulfilled, (state, { payload }) => {
      state.gptConversations = payload.data;
    });
    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      state.activeGptConversation = payload;
      state.gptConversations.push(payload);
    });
    builder.addCase(updateChat.fulfilled, (state, { payload }) => {
      if (state.activeGptConversation) {
        state.activeGptConversation.conversation_metadata.userPrompts.push(payload.userPrompt);
        state.activeGptConversation.conversation_data.output.push(payload.botResponse);
      }
    });
    builder.addCase(resetChat.fulfilled, (state, { payload }) => {
      state.activeGptConversation = null;
      state.gptConversations = payload.data;
    });
    builder.addCase(updateGptConversationName.fulfilled, (state, { payload }) => {
      const updatedGptConversationArray = [...state.gptConversations].map((conversation) => {
        if (Number(conversation.id) === Number(payload?.id)) {
          return payload;
        }
        return conversation;
      });
      state.gptConversations = updatedGptConversationArray;
    });
    builder.addCase(deleteGptConversation.fulfilled, (state, { payload }) => {
      const updatedGptConversationArray = [...state.gptConversations].filter((conversation) => {
        if (Number(conversation.id) === Number(payload?.id)) {
          return false;
        }
        return true;
      });
      state.gptConversations = updatedGptConversationArray;
      if (Number(state.activeGptConversation?.id) === Number(payload?.id)) {
        state.activeGptConversation = null;
      }
    });
  },
});

export const chatbotReducer = chatbotSlice.reducer;

export const getActiveGptConversation = (state: RootState) => state.chatbot.activeGptConversation;
export const getGptConversationsSortedByRecency = (state: RootState) => {
  const sortedConversations = [...state.chatbot.gptConversations].sort((a, b) => {
    const timestampA = new Date(a.updatedAt).getTime();
    const timestampB = new Date(b.updatedAt).getTime();
    return timestampB - timestampA; // Most recent first
  });

  return sortedConversations;
};
export const { setActiveGptConversation, resetActiveGptConversation } = chatbotSlice.actions;
