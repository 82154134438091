import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IEnduserToBeInserted } from "src/features/team/state/teamSlice";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import {
  IBuild,
  IProjectGroup,
  IProjectGroupElement,
  IProjectsPermissions,
  IThemeInfo,
} from "../../../model/model";
import { IMedia } from "../../../model/unityObject";
import { filterGroupResults } from "../../gallery/state/filterUtil";

export interface INewProjectGroup {
  accessType: string;
  newName: string;
  thumbnailId: number;
  menuMediaId: number;
  themeId: number;
}
interface IProjectNewParticipantsCsv {
  userId: number;
  projectId?: number;
  withMail: number;
  file: File;
}
export interface IProjectGroupAndProjects {
  projectGroup: IProjectGroup[];
  projectsGroupWithProjects: Array<IProjectElementsOfProjectGroup>;
}

export interface IProjectGroupElementsFromCustomAPI {
  id: number;
  order: number;
  project: {
    id: number;
    name: string;
    source_thumbnail: IProjectGroupElementsFromCustomAPISourceThumbnail;
    is_deleted?: boolean;
  };
  project_group: {
    id: number;
    name: string;
    source_thumbnail: IProjectGroupElementsFromCustomAPISourceThumbnail;
  };
}

export interface IProjectGroupWithProjects {
  id: number;
  organization: number;
  name: string;
  description: string;
  uuid: string;
  auth_type: string;
  source_thumbnail: {
    id: number;
    s3_url: string;
    size_in_bytes: number;
    filename: string;
  };
  createdAt: string;
  updatedAt: string;
  is_deleted: boolean;
  updated_at_ts: number;
  theme_info: IThemeInfo | null;
  project_group_elements: IProjectGroupElementsFromCustomAPI[];
  has_existing_build: boolean;
  creator: string;
  created_by?: string;
  publishing_history?: {
    version: number;
    projectIds: number[];
  }[];
  source_app_main_menu_media: {
    id: number;
    s3_url: string;
    size_in_bytes: number;
    filename: string;
  };
}

export interface IProjectGroupElementsFromCustomAPISourceThumbnail {
  id: number;
  s3_url: string;
  size_in_bytes: number;
  filename: string;
}

export interface IProjectElementsOfProjectGroup {
  projectGroupElements: Array<IProjectGroupElement>;
  projectGroupId: number;
  projectGroupName: string;
}

interface IPostProjectGroup {
  name: string;
  auth_type: string;
  source_thumbnail: number;
  organization: number;
  theme_info: number;
  created_by?: string;
  source_app_main_menu_media: number;
}

interface ISearchProjectGroup {
  name: string;
  actualGroupList: IProjectGroupWithProjects[];
}
interface ISearchProject {
  name: string;
  actualProjectList: Array<IProjectGroupElement>;
}

export interface IProjectOrder {
  order: number;
  projectId: number;
}

export interface IProjectGroupsState {
  allForSearch: Array<IProjectGroupWithProjects>;
  allWithNestedElements: Array<IProjectGroupWithProjects>;
  current: IProjectGroupWithProjects;
  projectGroupElements: Array<IProjectElementsOfProjectGroup>;
  currentElementsForSearch: Array<IProjectGroupElement>;
  currentElements: Array<IProjectGroupElement>;
  newProjectGroup: INewProjectGroup;
  currentProjectGroupBuilds: IBuild[];
  currentProjectGroupPermissions: Array<IProjectsPermissions>;
  edited: {
    name: string;
    source_thumbnail: IMedia;
    theme_info: IThemeInfo;
    source_app_main_menu_media: IMedia;
  };
  newUserFromCsv: IEnduserToBeInserted[];
  currentProjectGroupWeight: number;
  currentModeListProjectGroup: boolean;
}

interface IFilterProjectGroup {
  type: string;
  actualGroupList: IProjectGroupWithProjects[];
}

const initialState: IProjectGroupsState = {
  allForSearch: [],
  allWithNestedElements: [],
  projectGroupElements: [],
  currentElementsForSearch: [],
  currentProjectGroupPermissions: [],
  current: {
    id: 0,
    name: "",
    uuid: "",
    auth_type: "simplified",
    source_thumbnail: {
      id: 0,
      s3_url: "",
      filename: "",
      size_in_bytes: 0,
    },
    organization: 0,
    updated_at_ts: 0,
    description: "",
    createdAt: "",
    updatedAt: "",
    is_deleted: false,
    theme_info: null,
    project_group_elements: [],
    has_existing_build: false,
    creator: "",
    source_app_main_menu_media: {
      id: 0,
      s3_url: "",
      filename: "",
      size_in_bytes: 0,
    },
  },
  currentElements: [],
  newProjectGroup: {
    accessType: "classic",
    newName: "",
    thumbnailId: 47,
    themeId: 1,
    menuMediaId: 5346,
  },
  currentProjectGroupBuilds: [],
  edited: {
    name: "",
    source_thumbnail: {
      createdAt: "",
      id: 0,
      name: "",
      label: "",
      organization: 0,
      s3_url: "",
      type: "image",
      updatedAt: "",
      filename: "",
      size_in_bytes: 0,
      media_360_tag: "",
      MD5: "",
      upload_status: "completed",
    },
    theme_info: {
      id: 0,
    },
    source_app_main_menu_media: {
      createdAt: "",
      id: 0,
      name: "",
      label: "",
      organization: 0,
      s3_url: "",
      type: "image",
      updatedAt: "",
      filename: "",
      size_in_bytes: 0,
      media_360_tag: "",
      MD5: "",
      upload_status: "completed",
    },
  },
  newUserFromCsv: [],
  currentProjectGroupWeight: 0,
  currentModeListProjectGroup: false,
};

export const fetchAllCurrentElements = createAsyncThunk(
  "projectGroups/getCurrentElements",
  // eslint-disable-next-line no-empty-pattern
  async ({ projectGroupId }: { projectGroupId: number } & IAppLoaderAction) => {
    const response = (await axios.get)<Array<IProjectGroupElement>>(
      `${apiUrls.projects.projectGroupElements}?filters[project_group][id][$eq]=${projectGroupId}&populate[project][populate][source_thumbnail]=*&populate[project][populate][enabled_languages]=*&populate[project][populate][project_tags]=*&populate[project_group][populate][source_app_main_menu_media]=*`,
    );

    return { data: (await response).data };
  },
);

export const fetchProjectGroupsWithProjects = createAsyncThunk(
  "projectGroups/getProjectGroupsWithProjects",
  async ({ limit }: { limit?: number } & IAppLoaderAction) => {
    const url = limit
      ? `${apiUrls.projects.projectGroupsWithProjects}?limit=${limit}`
      : apiUrls.projects.projectGroupsWithProjects;
    const response = await axios.get<IProjectGroupWithProjects[]>(url);
    return { data: response.data };
  },
);

export const fetchSpecificProjectGroupWithProjects = createAsyncThunk(
  "projectGroups/fetchSpecificProjectGroupWithProjects",
  async ({ projectGroupId }: { projectGroupId?: number } & IAppLoaderAction) => {
    const url = `${apiUrls.projects.projectGroupsWithProjects}?projectGroupId=${projectGroupId}`;
    const response = await axios.get<IProjectGroupWithProjects>(url);
    return { data: response.data };
  },
);

export const uploadNewParticipant = createAsyncThunk(
  "projectGroups/uploadNewParticipant",
  async ({ userId, projectId, withMail, file }: IProjectNewParticipantsCsv & IAppLoaderAction) => {
    const data = {
      files: {
        csvFile: file,
      },
      data: JSON.stringify({
        userId: userId.toString(),
        withMail: withMail.toString(),
        projectId: projectId?.toString(),
      }),
    };

    const response = await axios.post<Array<IProjectGroupElement>>(`${apiUrls.csv.add}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { data: (await response).data };
  },
);

export const postProjectGroup = createAsyncThunk(
  "projectGroups/post",
  async ({
    name,
    source_thumbnail,
    auth_type,
    organization,
    theme_info,
    created_by,
    source_app_main_menu_media,
  }: IPostProjectGroup & IAppLoaderAction) => {
    const response = (await axios.post)<IProjectGroup>(
      `${apiUrls.projects.projectGroups}?populate[0]=organization&populate[1]=source_thumbnail&populate[2]=source_app_main_menu_media`,
      {
        name,
        auth_type,
        source_thumbnail,
        organization,
        theme_info,
        created_by,
        source_app_main_menu_media,
      },
    );
    return { data: (await response).data };
  },
);

export const updateProjectGroup = createAsyncThunk(
  "projectGroups/updateProjectGroup",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: any;
  } & IAppLoaderAction) => {
    return await axios
      .put(`${apiUrls.projects.projectGroups}/${id}`, {
        ...payload,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => console.error(e));
  },
);

export const deleteProjectGroup = createAsyncThunk(
  "projectGroups/delete",
  async ({ id }: { id: number } & IAppLoaderAction) => {
    const response = (await axios.put)<IProjectGroup>(`${apiUrls.projects.projectGroups}/${id}`, {
      is_deleted: true,
    });
    return { data: (await response).data };
  },
);

export const deleteMultipleProjectGroup = createAsyncThunk(
  "projectGroups/deleteMultipleProjectGroup",
  async ({ projectGroupListId }: { projectGroupListId: Array<number> } & IAppLoaderAction) => {
    const response = await axios.delete<Array<number>>(
      `${apiUrls.projects.multipleProjectGroups}?ids=${projectGroupListId.toString()}`,
    );
    return { data: response.data };
  },
);

export const deleteTheme = createAsyncThunk(
  "projectGroups/deleteTheme",
  async ({ themeId }: { themeId: number } & IAppLoaderAction) => {
    const response = await axios.delete(`${apiUrls.themeInfos}/${themeId.toString()}`);

    return { data: response.data };
  },
);

export const getProjectGroupPermissionsFromServer = createAsyncThunk(
  "projects/getProjectGroupPermissionsFromServer",
  // eslint-disable-next-line no-empty-pattern
  async (
    { projectGroupId }: { projectGroupId: number } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = (await axios.get)<Array<IProjectsPermissions>>(
        `${apiUrls.projects.projectUserPermissions}?filters[project_group][id][$eq]=${projectGroupId}&populate[project]=*&populate[project_group]=*&populate[user][populate][role]=*&populate[user][populate][source_avatar]=*`,
      );
      return { data: (await response).data };
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

export const reorderProjects = createAsyncThunk(
  "projects/reorderProjects",
  async ({ orders }: { orders: Array<IProjectOrder> } & IAppLoaderAction) => {
    const response = (await axios.post)<Array<IProjectOrder>>(
      `${apiUrls.projects.reorderProjects}`,
      {
        orders,
      },
    );
    return { data: (await response).data };
  },
);

export const getProjectGroupSize = createAsyncThunk(
  "projects/getProjectGroupSize",
  async ({ projectsIds }: { projectsIds: string } & IAppLoaderAction) => {
    const response = (await axios.get)(`${apiUrls.projects.projectGroupSize}?ids=${projectsIds}`);
    return { data: Number((await response).data) };
  },
);

export const projectGroupSlice = createSlice({
  name: "projectGroups",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCurrentElements.fulfilled, (state, { payload }) => {
      const currentElements = payload.data as Array<IProjectGroupElement>;
      state.currentElementsForSearch = currentElements;
      state.currentElements = currentElements;
    });

    builder.addCase(fetchProjectGroupsWithProjects.fulfilled, (state, { payload }) => {
      const newItems = payload.data;

      const mergeAndSortArrays = (oldArray, newArray) => {
        const mergedArray = oldArray.slice(); // Clone old array to avoid direct mutation

        newArray.forEach((newItem) => {
          const index = mergedArray.findIndex((oldItem) => oldItem.id === newItem.id);
          if (index !== -1) {
            mergedArray[index] = newItem; // Replace existing item
          } else {
            mergedArray.push(newItem); // Add new item
          }
        });

        // Sort the merged array by updated_at_ts in descending order
        mergedArray.sort((a, b) => {
          const tsA = Number(a.updated_at_ts);
          const tsB = Number(b.updated_at_ts);
          return tsB - tsA;
        });

        return mergedArray;
      };

      state.allWithNestedElements = mergeAndSortArrays(state.allWithNestedElements, newItems);
      state.allForSearch = mergeAndSortArrays(state.allForSearch, newItems);
    });

    builder.addCase(postProjectGroup.fulfilled, (state, { payload }) => {
      const reworkedPayload = {
        id: Number(payload.data.id),
        organization: Number(payload.data.organization.id),
        name: payload.data.name,
        description: payload.data.description,
        uuid: payload.data.uuid,
        auth_type: payload.data.auth_type,
        source_thumbnail: {
          id: Number(payload.data.source_thumbnail.id),
          s3_url: payload.data.source_thumbnail.s3_url,
          size_in_bytes: payload.data.source_thumbnail.size_in_bytes,
          filename: payload.data.source_thumbnail.filename,
        },
        createdAt: payload.data.created_at,
        updatedAt: payload.data.updated_at,
        is_deleted: payload.data.is_deleted,
        updated_at_ts: Number(payload.data.updated_at_ts),
        theme_info: payload.data.theme_info,
        project_group_elements: [],
      };

      const updatedAllWithNestedElements = [
        reworkedPayload,
        ...state.allWithNestedElements,
      ] as IProjectGroupWithProjects[];

      state.allForSearch = updatedAllWithNestedElements;
      state.allWithNestedElements = updatedAllWithNestedElements;
    });
    builder.addCase(deleteProjectGroup.fulfilled, (state, { payload }) => {
      const updatedProjectElementsOfProjectGroup = [...state.projectGroupElements].filter(
        (pge: IProjectElementsOfProjectGroup) =>
          Number(pge.projectGroupId) !== Number(payload.data.id),
      ) as Array<IProjectElementsOfProjectGroup>;

      const updatedAllWithNestedElements = [...state.allWithNestedElements].filter(
        (pge: IProjectGroupWithProjects) => Number(pge.id) !== Number(payload.data.id),
      );

      state.projectGroupElements = updatedProjectElementsOfProjectGroup;
      state.allWithNestedElements = updatedAllWithNestedElements;
      state.allForSearch = updatedAllWithNestedElements;
    });
    builder.addCase(deleteMultipleProjectGroup.fulfilled, (state, { payload }) => {
      const updatedProjectElementsOfProjectGroup = [...state.projectGroupElements].filter(
        (pge: IProjectElementsOfProjectGroup) => !payload.data.includes(Number(pge.projectGroupId)),
      ) as Array<IProjectElementsOfProjectGroup>;

      const updatedAllWithNestedElements = [...state.allWithNestedElements].filter(
        (pge: IProjectGroupWithProjects) => !payload.data.includes(Number(pge.id)),
      );

      state.projectGroupElements = updatedProjectElementsOfProjectGroup;
      state.allWithNestedElements = updatedAllWithNestedElements;
      state.allForSearch = updatedAllWithNestedElements;
    });
    builder.addCase(deleteTheme.fulfilled, (state, { payload }) => {
      const updatedProjectWithNewThemes = [...state.allWithNestedElements].map((pgwne) => {
        const matchingTheme = payload?.data[pgwne.id];

        if (matchingTheme) {
          return { ...pgwne, theme_info: payload.data.theme_info.id };
        }
        return pgwne;
      });
      const currentProjectGroup = { ...state.current };
      const findUpdatedCurrent = updatedProjectWithNewThemes.find(
        (pgwne) => pgwne.id === currentProjectGroup.id,
      );

      state.allWithNestedElements = updatedProjectWithNewThemes;
      state.allForSearch = updatedProjectWithNewThemes;
      state.current = findUpdatedCurrent as IProjectGroupWithProjects;
    });

    builder.addCase(getProjectGroupSize.fulfilled, (state, { payload }) => {
      state.currentProjectGroupWeight = payload.data;
    });
    builder.addCase(updateProjectGroup.fulfilled, (state, { payload }) => {
      if (payload.theme_info) {
        payload.theme_info = payload.theme_info.id
          ? Number(payload.theme_info.id)
          : Number(payload.theme_info);
      }
      state.current = payload;
      state.edited = payload;
      const updatedPgWithNestedElements = [...state.allWithNestedElements].map((pgwne) => {
        return Number(pgwne.id) === Number(payload.id) ? { ...pgwne, ...payload } : pgwne;
      });
      state.allForSearch = updatedPgWithNestedElements;
      state.allWithNestedElements = updatedPgWithNestedElements;
    });
    builder.addCase(fetchSpecificProjectGroupWithProjects.fulfilled, (state, { payload }) => {
      const specificProjectGroup = payload.data;

      const updateProjectGroupList = (list: IProjectGroupWithProjects[]) => {
        const updatedList = [...list];
        const existingIndex = updatedList.findIndex((pg) => pg.id === specificProjectGroup.id);

        if (existingIndex >= 0) {
          console.log(
            "fetchSpecificProjectGroupWithProjects.fulfilled : updating entry with id ",
            specificProjectGroup.id,
          );
          updatedList[existingIndex] = specificProjectGroup;
        } else {
          console.log(
            "fetchSpecificProjectGroupWithProjects.fulfilled : pushing new entry with id ",
            specificProjectGroup.id,
          );
          updatedList.push(specificProjectGroup);
        }
        return updatedList;
      };

      state.allWithNestedElements = updateProjectGroupList(state.allWithNestedElements);
      state.allForSearch = updateProjectGroupList(state.allForSearch);
    });
  },

  reducers: {
    setSelectedProjectGroup: (
      _state: IProjectGroupsState,
      action: PayloadAction<IProjectGroupWithProjects>,
    ) => {
      _state.current = action.payload;
      _state.edited.name = action.payload.name;
      _state.edited.source_thumbnail = { ...action.payload.source_thumbnail } as IMedia;
      _state.edited.source_app_main_menu_media = {
        ...action.payload.source_app_main_menu_media,
      } as IMedia;
    },
    setSelectedProjectGroupFromId: (_state: IProjectGroupsState, action: PayloadAction<number>) => {
      const moduleId = action.payload;
      const pgObject = _state.allWithNestedElements.find((project) => project.id === moduleId);

      if (pgObject) {
        _state.current = pgObject as IProjectGroupWithProjects;
        _state.edited.name = pgObject.name;
        _state.edited.source_thumbnail = { ...pgObject.source_thumbnail } as IMedia;
        _state.edited.source_app_main_menu_media = {
          ...pgObject.source_app_main_menu_media,
        } as IMedia;
      }
    },
    setSelectedProjectGroupFromProjectGroupId: (
      _state: IProjectGroupsState,
      action: PayloadAction<number>,
    ) => {
      const projectGroup = _state.allWithNestedElements.find(
        (pg) => pg.id === action.payload,
      ) as IProjectGroupWithProjects;
      _state.current = projectGroup;
      _state.edited.name = projectGroup.name;
    },
    setCurrentProjectGroupName: (_state: IProjectGroupsState, action: PayloadAction<string>) => {
      _state.current.name = action.payload;
    },
    setNewProjectGroup: (_state: IProjectGroupsState, action: PayloadAction<INewProjectGroup>) => {
      _state.newProjectGroup = { ...action.payload };
    },
    setUpdateProject: (
      state: IProjectGroupsState,
      action: PayloadAction<Array<IProjectGroupElement>>,
    ) => {
      state.currentElements = action.payload;
    },
    setUserFromCsv: (state: IProjectGroupsState, action: PayloadAction<[]>) => {
      state.newUserFromCsv = action.payload;
    },
    addCurrentElement: (
      state: IProjectGroupsState,
      action: PayloadAction<IProjectGroupElement>,
    ) => {
      const updatedCurrentElements = [...state.currentElements, action.payload];
      const projectGroupId = action.payload.project_group.id;

      state.currentElements = updatedCurrentElements;
      state.currentElementsForSearch = updatedCurrentElements;

      const updateProjectGroupElements = (array: IProjectGroupWithProjects[]) => {
        return array.map((projectGroup) => {
          if (projectGroup.id === projectGroupId) {
            const updatedProjectGroupElements = [
              ...projectGroup.project_group_elements,
              action.payload,
            ] as typeof projectGroup.project_group_elements;
            return {
              ...projectGroup,
              project_group_elements: updatedProjectGroupElements,
            } as IProjectGroupWithProjects;
          }
          return projectGroup;
        }) as IProjectGroupWithProjects[];
      };

      state.allForSearch = updateProjectGroupElements(state.allForSearch);
      state.allWithNestedElements = updateProjectGroupElements(state.allWithNestedElements);
    },
    removeElementsOfProject: (
      state: IProjectGroupsState,
      action: PayloadAction<string | number>,
    ) => {
      const updatedCurrentElements = [...state.currentElements].filter(
        (pge: IProjectGroupElement) => {
          return Number(pge.project.id) !== Number(action.payload);
        },
      );
      state.currentElements = updatedCurrentElements;
      state.currentElementsForSearch = updatedCurrentElements;

      const updatedPgList = state.allWithNestedElements.map((pgwne) => {
        return {
          ...pgwne,
          project_group_elements: [...pgwne.project_group_elements]
            .sort((a, b) => a.order - b.order)
            .filter((pge) => Number(pge.project.id) !== Number(action.payload)),
        };
      });

      const updatedCurrentProjectGroup = state.current.project_group_elements
        ? {
            ...state.current,
            project_group_elements: [...state.current.project_group_elements]
              .sort((a, b) => a.order - b.order)
              .filter((pge) => Number(pge.project.id) !== Number(action.payload)),
          }
        : state.current;

      state.allWithNestedElements = updatedPgList;
      state.allForSearch = updatedPgList;
      state.current = updatedCurrentProjectGroup;
    },
    setCurrentProjectGroupPermissions: (
      state: IProjectGroupsState,
      action: PayloadAction<IProjectsPermissions[]>,
    ) => {
      state.currentProjectGroupPermissions = action.payload;
    },
    setFilterProjectGroup: (
      state: IProjectGroupsState,
      action: PayloadAction<IFilterProjectGroup>,
    ) => {
      const filter = action.payload.type;
      const list = action.payload.actualGroupList;
      const splitFilter = filter?.split("_");
      const getFilteredList = filterGroupResults(list, splitFilter);
      state.allWithNestedElements = getFilteredList;
    },
    setSearchProjectGroups: (
      state: IProjectGroupsState,
      action: PayloadAction<ISearchProjectGroup>,
    ) => {
      const nameProjectGroup = action.payload.name;
      if (nameProjectGroup !== "") {
        const filterNameForSearch = action.payload.actualGroupList.filter((group) =>
          group.name && group.name !== "" ? action.payload.name.includes(group.name) : false,
        );
        state.allWithNestedElements = filterNameForSearch;
      } else {
        state.allWithNestedElements = action.payload.actualGroupList;
      }
    },
    setSearchProject: (state: IProjectGroupsState, action: PayloadAction<ISearchProject>) => {
      const nameProject = action.payload.name;
      if (nameProject !== "") {
        const filterNameForSearch = action.payload.actualProjectList.filter((group) =>
          group.project.name && group.project.name !== ""
            ? action.payload.name.includes(group.project.name)
            : false,
        );
        state.currentElements = filterNameForSearch;
      } else {
        state.currentElements = action.payload.actualProjectList;
      }
    },
    setCurrentElements: (
      state: IProjectGroupsState,
      action: PayloadAction<Array<IProjectGroupElement>>,
    ) => {
      state.currentElements = action.payload;
      state.currentElementsForSearch = action.payload;
    },
    setCurrentModeListProjectGroup: (
      state: IProjectGroupsState,
      action: PayloadAction<boolean>,
    ) => {
      state.currentModeListProjectGroup = action.payload;
    },
    setEdited: (state: IProjectGroupsState) => {
      state.edited = {
        name: state.current.name,
        source_thumbnail: state.current
          .source_thumbnail as IProjectGroupElementsFromCustomAPISourceThumbnail & IMedia,
        theme_info: state.current.theme_info as IThemeInfo,
        source_app_main_menu_media: state.current.source_app_main_menu_media as IMedia,
      };
    },
    setEditedName: (state: IProjectGroupsState, action: PayloadAction<string>) => {
      state.edited.name = action.payload;
    },
    setEditedThumbnail: (state: IProjectGroupsState, action: PayloadAction<IMedia>) => {
      state.edited.source_thumbnail = action.payload;
    },
    clearProjectGroups: (state: IProjectGroupsState) => {
      (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        (state[key] as any) = initialState[key];
      });
    },
    setProjectGroupsWithProjects: (
      state: IProjectGroupsState,
      action: PayloadAction<IProjectGroupWithProjects[]>,
    ) => {
      state.allWithNestedElements = action.payload;
      state.allForSearch = action.payload;
    },
    updateProjectGroupThumbnail: (state: IProjectGroupsState, action: PayloadAction<IMedia>) => {
      const bingo = (pg: any) => Number(pg.source_thumbnail.id) === Number(action.payload.id);

      state.allWithNestedElements = [...state.allWithNestedElements].map(
        (pg: IProjectGroupWithProjects) => {
          if (bingo(pg)) {
            return {
              ...pg,
              source_thumbnail: action.payload,
            } as IProjectGroupWithProjects;
          } else {
            return pg;
          }
        },
      );
      state.current.source_thumbnail =
        action.payload as IProjectGroupElementsFromCustomAPISourceThumbnail & IMedia;
      state.edited.source_thumbnail = action.payload;
    },
    setEditedMenuMedia: (state: IProjectGroupsState, action: PayloadAction<IMedia>) => {
      state.edited.source_app_main_menu_media = action.payload;
    },
    setCurrentProjectGroupAsEdited: (state: IProjectGroupsState) => {
      state.edited = {
        name: state.current.name,
        source_thumbnail: state.current.source_thumbnail as IMedia,
        theme_info: state.current.theme_info,
        source_app_main_menu_media: state.current.source_app_main_menu_media as IMedia,
      };
    },
  },
});

export const projectGroupReducer = projectGroupSlice.reducer;

export const getCurrentProjectGroupForSearch = (state: RootState) =>
  state.projectGroups.allForSearch;

export const getAllProjectGroupsWithNestedElements = (state: RootState) =>
  state.projectGroups.allWithNestedElements;

export const getCurrentProjectForSearch = (state: RootState) =>
  state.projectGroups.currentElementsForSearch;

export const getSelectedProjectGroup = (state: RootState) => state.projectGroups.current;

export const getEditedProjectGroup = (state: RootState) => state.projectGroups.edited;

export const getCurrentElementsOfProjectGroup = (state: RootState) =>
  state.projectGroups.projectGroupElements;

export const getCurrentElements = (state: RootState) => state.projectGroups.currentElements;

export const getNewProjectGroup = (state: RootState) => state.projectGroups.newProjectGroup;

export const getCurrentProjectGroupBuilds = (state: RootState) =>
  state.projectGroups.currentProjectGroupBuilds;

export const getCurrentProjectGroupPermissions = (state: RootState) =>
  state.projectGroups.currentProjectGroupPermissions;
export const getCurrentUsersFromCsv = (state: RootState) => state.projectGroups.newUserFromCsv;

export const getCurrentProjectGroupWeight = (state: RootState) =>
  state.projectGroups.currentProjectGroupWeight;

export const getCurrentModeListProjectGroup = (state: RootState) =>
  state.projectGroups.currentModeListProjectGroup;

export const {
  setUserFromCsv,
  setSelectedProjectGroup,
  setSelectedProjectGroupFromId,
  setCurrentProjectGroupName,
  setSelectedProjectGroupFromProjectGroupId,
  setNewProjectGroup,
  setUpdateProject,
  addCurrentElement,
  setCurrentModeListProjectGroup,
  removeElementsOfProject,
  setCurrentProjectGroupPermissions,
  setSearchProjectGroups,
  setFilterProjectGroup,
  setSearchProject,
  setEdited,
  setEditedName,
  setEditedThumbnail,
  clearProjectGroups,
  setCurrentElements,
  updateProjectGroupThumbnail,
  setProjectGroupsWithProjects,
  setEditedMenuMedia,
  setCurrentProjectGroupAsEdited,
} = projectGroupSlice.actions;
