import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/common/state/hooks";
import { createTemplateFromAiGraph } from "src/features/projects/state/projectsSlice";
import { generateProjectGroupFromAiGraph, getActiveGptConversation } from "../state/chatbotSlice";
import { CHATBOT_COMPONENT_ID, createProjectFromAiGraphModalId } from "./Chatbot";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";
import { setAlert } from "src/features/alert/state/alertsSlice";
import { fetchSpecificProjectGroupWithProjects } from "src/features/projectGroups/state/projectGroupsSlice";

const leTimestampDuBaguetteGmtPlusDeux = new Date(Date.now() + 2 * 60 * 60 * 1000);
const prettyGenerationDate = new Intl.DateTimeFormat("fr-FR").format(
  leTimestampDuBaguetteGmtPlusDeux,
);

const CreateProjectFromAiGraph = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeGptConversation = useSelector(getActiveGptConversation);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState(`Généré le ${prettyGenerationDate}`);
  const [errorMessage, setErrorMessage] = useState("");

  const validateAndSubmit = () => {
    if (!name) {
      setErrorMessage("Le nom ne peut pas être vide.");
      return;
    }
    if (name.length > 24) {
      setErrorMessage("Le nom ne peut pas dépasser 24 caractères.");
      return;
    }
    setErrorMessage("");
    if (selectedOption === "generateTemplate") {
      dispatch(
        createTemplateFromAiGraph({
          name,
          conversationId: activeGptConversation.id,
          componentId: CHATBOT_COMPONENT_ID,
          cb: () => {
            dispatch(
              setAlert({
                msg: t("pages.chat.createProjectFromAiGraph.templateGeneratedSuccessfully"),
                type: "success",
                isOpen: true,
                customTimeoutInMs: 15000,
              }),
            );
          },
        }),
      );
      dispatch(closeStandardModal(createProjectFromAiGraphModalId));
    }
    if (selectedOption === "generateProjectGroup") {
      dispatch(
        generateProjectGroupFromAiGraph({
          name,
          conversationId: activeGptConversation.id,
          componentId: CHATBOT_COMPONENT_ID,
        }),
      ).then((res) => {
        console.log("res in dispatch( generateProjectGroupFromAiGraph");
        const createdProjectGroupId = (res.payload as { createdProjectGroup: { id: number } })
          .createdProjectGroup.id;
        dispatch(
          fetchSpecificProjectGroupWithProjects({
            projectGroupId: createdProjectGroupId,
            componentId: CHATBOT_COMPONENT_ID,
          }),
        ).then(() => {
          dispatch(
            setAlert({
              msg: t("pages.chat.createProjectFromAiGraph.projectGroupGeneratedSuccessfully"),
              type: "success",
              isOpen: true,
              customTimeoutInMs: 15000,
            }),
          );
        });

        return res;
      });

      dispatch(closeStandardModal(createProjectFromAiGraphModalId));
    }
    // generateProjectGroupFromAiGraph
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const SelectAnOption = () => (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">{t("pages.chat.createProjectFromAiGraph.title")}</h2>
      <div className="flex w-3/5 items-center justify-between">
        <button className="btn-primary-fill" onClick={() => setSelectedOption("generateTemplate")}>
          {t("pages.chat.createProjectFromAiGraph.generateTemplate")}
        </button>
        <button
          className="btn-primary-fill"
          onClick={() => setSelectedOption("generateProjectGroup")}
        >
          {t("pages.chat.createProjectFromAiGraph.generateProjectGroup")}
        </button>
      </div>
    </div>
  );

  const GenerateTemplate = () => (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">
        {t("pages.chat.createProjectFromAiGraph.nameNewTemplate")}
      </h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Entrez un nom de modèle"
        className="border rounded px-3 py-2 w-3/5 mb-4"
        autoFocus
      />
      {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>}
      <button className="btn-primary-fill" onClick={validateAndSubmit}>
        {t("general.generate")}
      </button>
    </div>
  );

  const GenerateProjectGroup = () => (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">
        {t("pages.chat.createProjectFromAiGraph.nameNewProjectGroup")}
      </h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Entrez un nom de groupe de projet"
        className="border rounded px-3 py-2 w-3/5 mb-4"
        autoFocus
      />
      {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>}
      <button className="btn-primary-fill" onClick={validateAndSubmit}>
        {t("general.generate")}
      </button>
    </div>
  );

  const renderAppropriateComponent = () => {
    switch (selectedOption) {
      case "generateTemplate":
        return <GenerateTemplate />;
      case "generateProjectGroup":
        return <GenerateProjectGroup />;
      case "":
      default:
        return <SelectAnOption />;
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      {renderAppropriateComponent()}
    </div>
  );
};

export default CreateProjectFromAiGraph;
